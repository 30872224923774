import forbes from './62468545-0-fobes.png';
import forbesIndia from './62468543-0-Forbes-India.jpg';
import logo from './62485174-0-Group-3245.png';
import landPage from './62602724-0-Group-3628.png';
import downloadRedirect from './62518089-0-button-2.png';
import shopPerformace from './62506388-0-Shop-performance-2.png';
import myShop from './62506389-0-my-shop.png';
import products from './62497806-0-products.png';
import gullak2 from './62497838-0-gullack2.png';
import kamai from './62603270-0-kamai.png';
import customerKamai from './customers-kamai.png';
import testimonial1 from './62473223-0-testimonial-new-2.png';
import testimonial2 from './62498712-0-father-with-his-chil.png';
import testimonial3 from './62498778-0-old-lady-1.png';
import news from './Newspaper.png';
import cnbc from './62468549-0-Media2.jpg';
import techinasi from './62468554-0-media1.jpg';
import message from './62762498-0-message.png';
import team1 from './team1.png';
import team2 from './team2.jpeg';
import team3 from './team3.png';
import team4 from './team4.png';
import news1 from './62495581-0-Group-3613.png';
import news2 from './62495584-0-Group-3610.png';
import news3 from './62495589-0-Group-3612.png';

export default {
  forbes,
  forbesIndia,
  logo,
  landPage,
  downloadRedirect,
  shopPerformace,
  myShop,
  products,
  gullak2,
  kamai,
  customerKamai,
  testimonial1,
  testimonial2,
  testimonial3,
  news,
  cnbc,
  techinasi,
  message,
  team1,
  team2,
  team3,
  team4,
  news1,
  news2,
  news3,
};
