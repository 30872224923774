import React from 'react';
import { Link } from 'react-router-dom';
import './styles/App.css';
import './styles/main.css';
import './styles/fonts.css';
import Images from './images/images';

const App = () => {
  function stickyFooter() {
    var footer = document.querySelector('.section:last-child');
    var footerHeight = footer.clientHeight;
    footer.classList.add('sticky-footer');
    document.querySelector('body').style.marginBottom = footerHeight + 'px';
  }

  return (
    <div>
      <section
        class="section section-relative"
        id="page-block-eex4ic3yqa4"
        data-at="section">
        <div
          class="section-holder-border item-block item-absolute"
          data-at="section-border"
          bis_skin_checked="1"></div>
        <div
          class="section-holder-overlay item-block item-absolute"
          data-at="section-overlay"
          bis_skin_checked="1"></div>
        <div class="section-block" bis_skin_checked="1">
          <div
            class="section-inner section-fit section-relative"
            bis_skin_checked="1">
            <div
              class="widget item-absolute"
              id="element-484"
              bis_skin_checked="1">
              <div
                class="contents cropped item-block"
                data-at="image-cropp"
                bis_skin_checked="1">
                <img
                  class="item-content-box item-block image"
                  data-at="image"
                  alt=""
                  src={Images.logo}
                  srcset={Images.logo}
                />
              </div>
            </div>

            <div
              class="widget item-absolute"
              id="element-737"
              bis_skin_checked="1">
              <div
                class="contents cropped item-block"
                data-at="image-cropp"
                bis_skin_checked="1">
                <img
                  class="item-content-box item-block image"
                  data-at="image"
                  alt=""
                  src={Images.landPage}
                  srcset={Images.landPage}
                />
              </div>
            </div>

            <div
              class="widget item-absolute headline"
              id="element-2"
              data-at="headline"
              bis_skin_checked="1">
              <div class="contents" bis_skin_checked="1">
                <h1>
                  <span class="x_abeb1804 x_b796efc8">
                    <strong>
                      <span class="x_be5cc131">
                        शुरू करे अपना पार्ट टाइम बिज़नेस और
                      </span>
                    </strong>
                  </span>
                  <span class="x_abeb1804 x_b796efc8">
                    <strong>
                      <span class="x_be5cc131">कमाए </span>
                      <span class="x_1dad93f4">₹10,000 </span>
                      <span class="x_be5cc131">हर महीने बस दिन के</span>&nbsp;
                      <span class="x_1dad93f4">2-3 घंटे </span>
                      <span class="x_be5cc131">देकर</span>
                    </strong>
                  </span>
                </h1>
              </div>
            </div>

            <div
              class="widget item-absolute"
              id="element-705"
              bis_skin_checked="1">
              <div
                class="contents cropped item-block"
                data-at="image-cropp"
                bis_skin_checked="1">
                <a
                  href="https://cl.ctml.in/aRHVlSLzsFfV"
                  class="url-link item-content-box item-block"
                  id="link-ai947mj6e99"
                  data-at="image-link"
                  data-link-ai947mj6e99=""
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    class="item-content-box item-block image"
                    data-at="image"
                    alt=""
                    src={Images.downloadRedirect}
                    srcset={Images.downloadRedirect}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        class="section section-relative"
        id="page-block-9vzy99jex0e"
        data-at="section">
        <div
          class="section-holder-border item-block item-absolute"
          data-at="section-border"
          bis_skin_checked="1"></div>
        <div
          class="section-holder-overlay item-block item-absolute"
          data-at="section-overlay"
          bis_skin_checked="1"></div>
        <div class="section-block" bis_skin_checked="1">
          <div
            class="section-inner section-fit section-relative"
            bis_skin_checked="1">
            <div
              class="widget item-absolute headline"
              id="element-756"
              data-at="headline"
              bis_skin_checked="1">
              <div class="contents" bis_skin_checked="1">
                <h1>
                  <span class="x_190a7a83 x_06cb0376">
                    <span class="x_f2074b6c">सिटिमॉल के साथ जुड़े है</span>
                  </span>
                </h1>
              </div>
            </div>

            <div
              class="widget item-absolute hidden-mobile"
              id="element-763"
              bis_skin_checked="1">
              <div
                class="contents shape box figure"
                data-at="shape"
                bis_skin_checked="1"></div>
            </div>

            <div
              class="widget item-absolute"
              id="element-768"
              bis_skin_checked="1">
              <div
                class="contents shape box figure"
                data-at="shape"
                bis_skin_checked="1"></div>
            </div>

            <div
              class="widget item-absolute"
              id="element-767"
              bis_skin_checked="1">
              <div
                class="contents shape box figure"
                data-at="shape"
                bis_skin_checked="1"></div>
            </div>

            <div
              class="widget item-absolute"
              id="element-769"
              bis_skin_checked="1">
              <div
                class="contents shape box figure"
                data-at="shape"
                bis_skin_checked="1"></div>
            </div>

            <div
              class="widget item-absolute headline"
              id="element-757"
              data-at="headline"
              bis_skin_checked="1">
              <div class="contents" bis_skin_checked="1">
                <h1>
                  <span class="x_07204851 x_86b935ec">
                    <strong>
                      <span class="x_f2074b6c">1 </span>
                    </strong>
                    <strong class="x_92851e38">
                      <span class="x_f2074b6c">करोड़</span>
                    </strong>
                  </span>
                </h1>
              </div>
            </div>

            <div
              class="widget item-absolute headline"
              id="element-761"
              data-at="headline"
              bis_skin_checked="1">
              <div class="contents" bis_skin_checked="1">
                <h1>
                  <span class="x_07204851 x_86b935ec">
                    <strong>
                      <span class="x_f2074b6c">50 हज़ार </span>
                    </strong>
                  </span>
                </h1>
              </div>
            </div>

            <div
              class="widget item-absolute headline"
              id="element-759"
              data-at="headline"
              bis_skin_checked="1">
              <div class="contents" bis_skin_checked="1">
                <h1>
                  <span class="x_07204851 x_86b935ec">
                    <strong>
                      <span class="x_f2074b6c">25+</span>
                    </strong>
                  </span>
                </h1>
              </div>
            </div>

            <div
              class="widget item-absolute headline"
              id="element-760"
              data-at="headline"
              bis_skin_checked="1">
              <div class="contents" bis_skin_checked="1">
                <h1>
                  <span class="x_76fda3f1 x_dc68832a">
                    <span class="x_f2074b6c">
                      <strong>शहर </strong>
                    </span>
                  </span>
                </h1>
              </div>
            </div>

            <div
              class="widget item-absolute headline"
              id="element-762"
              data-at="headline"
              bis_skin_checked="1">
              <div class="contents" bis_skin_checked="1">
                <h1>
                  <span class="x_1925a137 x_dc68832a">
                    <strong>
                      <span class="x_f2074b6c">कस्टमर्स</span>
                    </strong>
                  </span>
                </h1>
              </div>
            </div>

            <div
              class="widget item-absolute headline"
              id="element-758"
              data-at="headline"
              bis_skin_checked="1">
              <div class="contents" bis_skin_checked="1">
                <h1>
                  <span class="x_1925a137 x_dc68832a">
                    <span class="x_f2074b6c">
                      <strong>पार्टनर्स </strong>
                    </span>
                  </span>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        class="section section-relative"
        id="page-block-buh4ftm7fl8"
        data-at="section">
        <div
          class="section-holder-border item-block item-absolute"
          data-at="section-border"
          bis_skin_checked="1"></div>
        <div
          class="section-holder-overlay item-block item-absolute"
          data-at="section-overlay"
          bis_skin_checked="1"></div>
        <div class="section-block" bis_skin_checked="1">
          <div
            class="section-inner section-fit section-relative"
            bis_skin_checked="1">
            <div
              class="widget item-absolute"
              id="element-678"
              bis_skin_checked="1">
              <div
                class="contents cropped item-block"
                data-at="image-cropp"
                bis_skin_checked="1"></div>
            </div>

            <div
              class="widget item-absolute"
              id="element-384"
              bis_skin_checked="1">
              <div
                class="contents shape box figure"
                data-at="shape"
                bis_skin_checked="1"></div>
            </div>

            <div
              class="widget item-absolute paragraph"
              id="element-148"
              data-at="paragraph"
              bis_skin_checked="1">
              <div class="contents" bis_skin_checked="1">
                <p class="x_a257d155 x_33879449">
                  <em>
                    <span class="x_f2074b6c">
                      "CityMall से जुड़कर मुझे मिली आर्थिक आजादी
                    </span>
                  </em>
                  <em class="x_686005f3">
                    <span class="x_f2074b6c">&nbsp;</span>
                  </em>
                  <em class="x_686005f3">
                    <span class="x_f2074b6c">"</span>
                  </em>
                </p>
              </div>
            </div>

            <div
              class="widget item-absolute paragraph"
              id="element-155"
              data-at="paragraph"
              bis_skin_checked="1">
              <div class="contents" bis_skin_checked="1">
                <p class="x_e6deb37d x_8c071feb">
                  <strong class="x_92851e38">
                    <span class="x_66d2bef0">अंकिता तिवारी </span>
                  </strong>
                  <br />
                </p>
              </div>
            </div>

            <div
              class="widget item-absolute paragraph"
              id="element-325"
              data-at="paragraph"
              bis_skin_checked="1">
              <div class="contents" bis_skin_checked="1">
                <p class="x_1d9c64ff x_fae6260b">
                  <span class="x_f2074b6c">
                    पिछले महीने की कमाई : ₹9,753&nbsp;
                  </span>
                  <br />
                </p>
              </div>
            </div>

            <div
              class="widget item-absolute"
              id="element-441"
              bis_skin_checked="1">
              <div
                class="contents cropped item-block"
                data-at="image-cropp"
                bis_skin_checked="1"></div>
            </div>

            <div
              class="widget item-absolute"
              id="element-449"
              bis_skin_checked="1">
              <div
                class="contents shape box figure"
                data-at="shape"
                bis_skin_checked="1"></div>
            </div>

            <div
              class="widget item-absolute paragraph"
              id="element-450"
              data-at="paragraph"
              bis_skin_checked="1">
              <div class="contents" bis_skin_checked="1">
                <p class="x_3b25f75b x_33879449">
                  <em>
                    <span class="x_f2074b6c">
                      "CityMall की कमाई से मैंने खरीदी अपनी पहली बाइक "
                    </span>
                  </em>
                </p>
              </div>
            </div>

            <div
              class="widget item-absolute paragraph"
              id="element-451"
              data-at="paragraph"
              bis_skin_checked="1">
              <div class="contents" bis_skin_checked="1">
                <p class="x_0b167133 x_8c071feb">
                  <strong>
                    <span class="x_f2074b6c">सौरभ गुप्ता</span>
                  </strong>
                </p>
              </div>
            </div>

            <div
              class="widget item-absolute paragraph"
              id="element-452"
              data-at="paragraph"
              bis_skin_checked="1">
              <div class="contents" bis_skin_checked="1">
                <p class="x_004937d8 x_fae6260b">
                  <span class="x_f2074b6c">पिछले महीने की कमाई : ₹10,530 </span>
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        style={{ marginTop: '-150px' }}
        class="section section-relative desktop-only"
        id="page-block-ne7ha3gxh8l"
        data-at="section">
        <div
          class="section-holder-border item-block item-absolute"
          data-at="section-border"
          bis_skin_checked="1"></div>
        <div
          class="section-holder-overlay item-block item-absolute"
          data-at="section-overlay"
          bis_skin_checked="1"></div>
        <div class="section-block" bis_skin_checked="1">
          <div
            class="section-inner section-fit section-relative"
            bis_skin_checked="1">
            <div
              class="widget item-absolute headline"
              id="element-680"
              data-at="headline"
              bis_skin_checked="1">
              <div class="contents" bis_skin_checked="1">
                <h1>
                  <span class="x_e22090b4 x_b558b569">
                    <span class="x_f2074b6c">
                      हमारे बिज़नेस पार्टनर्स CityMall से अब तक कर चुके है{' '}
                    </span>
                    <strong>
                      <span class="x_1dad93f4">100 करोड़</span>
                    </strong>
                    <span class="x_f2074b6c"> की कमाई&nbsp;</span>
                  </span>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        class="section section-relative mobile-only"
        id="page-block-ne7ha3gxh8l"
        data-at="section">
        <div
          class="section-holder-border item-block item-absolute"
          data-at="section-border"
          bis_skin_checked="1"></div>
        <div
          class="section-holder-overlay item-block item-absolute"
          data-at="section-overlay"
          bis_skin_checked="1"></div>
        <div class="section-block" bis_skin_checked="1">
          <div
            class="section-inner section-fit section-relative"
            bis_skin_checked="1">
            <div
              class="widget item-absolute headline"
              id="element-680"
              data-at="headline"
              bis_skin_checked="1">
              <div class="contents" bis_skin_checked="1">
                <h1>
                  <span class="x_e22090b4 x_b558b569">
                    <span class="x_f2074b6c">
                      हमारे बिज़नेस पार्टनर्स CityMall से अब तक कर चुके है{' '}
                    </span>
                    <strong>
                      <span class="x_1dad93f4">100 करोड़</span>
                    </strong>
                    <span class="x_f2074b6c"> की कमाई&nbsp;</span>
                  </span>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        class="section section-relative"
        id="page-block-laxcqvw96w8"
        data-at="section">
        <div
          class="section-holder-border item-block item-absolute"
          data-at="section-border"
          bis_skin_checked="1"></div>
        <div
          class="section-holder-overlay item-block item-absolute"
          data-at="section-overlay"
          bis_skin_checked="1"></div>
        <div class="section-block" bis_skin_checked="1">
          <div
            class="section-inner section-fit section-relative"
            bis_skin_checked="1">
            <div
              class="widget item-absolute headline"
              id="element-337"
              data-at="headline"
              bis_skin_checked="1">
              <div class="contents" bis_skin_checked="1">
                <h1>
                  <span class="x_11909a12 x_daf2fc4f">
                    <strong>
                      यहां कमाना है बहुत&nbsp;ही
                      <span class="x_1dad93f4">आसान</span>
                    </strong>
                  </span>
                </h1>
              </div>
            </div>

            <div
              class="widget item-absolute"
              id="element-651"
              bis_skin_checked="1">
              <div
                class="contents cropped item-block"
                data-at="image-cropp"
                bis_skin_checked="1">
                <a
                  href="https://cl.ctml.in/aRHVlSLzsFfV"
                  class="url-link item-content-box item-block"
                  id="link-9jn13qflypq"
                  data-at="image-link"
                  data-link-9jn13qflypq=""
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    class="item-content-box item-block image"
                    data-at="image"
                    alt=""
                    src={Images.shopPerformace}
                    data-src={Images.shopPerformace}
                    data-retina-src={Images.shopPerformace}
                    srcset={Images.shopPerformace}
                  />
                </a>
              </div>
            </div>

            <div
              class="widget item-absolute"
              id="element-650"
              bis_skin_checked="1">
              <div
                class="contents cropped item-block"
                data-at="image-cropp"
                bis_skin_checked="1">
                <a
                  href="https://cl.ctml.in/aRHVlSLzsFfV"
                  class="url-link item-content-box item-block"
                  id="link-mm5is6cds9"
                  data-at="image-link"
                  data-link-mm5is6cds9=""
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    class="item-content-box item-block image"
                    data-at="image"
                    alt=""
                    src={Images.myShop}
                    data-src={Images.myShop}
                    data-retina-src={Images.myShop}
                    srcset={Images.myShop}
                  />
                </a>
              </div>
            </div>

            <div
              class="widget item-absolute"
              id="element-777"
              bis_skin_checked="1">
              <div
                class="contents shape circle figure"
                data-at="shape"
                bis_skin_checked="1"></div>
            </div>

            <div
              class="widget item-absolute headline"
              id="element-778"
              data-at="headline"
              bis_skin_checked="1">
              <div class="contents" bis_skin_checked="1">
                <h1>
                  <span class="x_dc68832a">
                    <span class="x_be5cc131">
                      <strong> 2</strong>
                    </span>
                  </span>
                </h1>
              </div>
            </div>

            <div
              class="widget item-absolute"
              id="element-772"
              bis_skin_checked="1">
              <div
                class="contents shape circle figure"
                data-at="shape"
                bis_skin_checked="1"></div>
            </div>

            <div
              class="widget item-absolute headline"
              id="element-773"
              data-at="headline"
              bis_skin_checked="1">
              <div class="contents" bis_skin_checked="1">
                <h1>
                  <span class="x_dc68832a">
                    <span class="x_be5cc131">
                      <strong> 1</strong>
                    </span>
                  </span>
                </h1>
              </div>
            </div>

            <div
              class="widget item-absolute paragraph"
              id="element-775"
              data-at="paragraph"
              bis_skin_checked="1">
              <div class="contents" bis_skin_checked="1">
                <p class="x_06cb0376 x_8c071feb">
                  <strong>
                    नए कस्टमर्स को जोड़े अपनी डिजिटल दूकान से&nbsp;
                  </strong>
                </p>
              </div>
            </div>

            <div
              class="widget item-absolute paragraph"
              id="element-774"
              data-at="paragraph"
              bis_skin_checked="1">
              <div class="contents" bis_skin_checked="1">
                <p class="x_7df451b3 x_9a9d3d17">
                  <strong>ऐप डाउनलोड करे और खोले अपनी डिजिटल दूकान</strong>
                </p>
              </div>
            </div>

            <div
              class="widget item-absolute"
              id="element-643"
              bis_skin_checked="1">
              <div
                class="contents cropped item-block"
                data-at="image-cropp"
                bis_skin_checked="1">
                <a
                  href="https://cl.ctml.in/aRHVlSLzsFfV"
                  class="url-link item-content-box item-block"
                  id="link-8dk5yiooui9"
                  data-at="image-link"
                  data-link-8dk5yiooui9=""
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    class="item-content-box item-block image"
                    data-at="image"
                    alt=""
                    src={Images.products}
                    data-src={Images.products}
                    data-retina-src={Images.products}
                    srcset={Images.products}
                  />
                </a>
              </div>
            </div>

            <div
              class="widget item-absolute"
              id="element-645"
              bis_skin_checked="1">
              <div
                class="contents cropped item-block"
                data-at="image-cropp"
                bis_skin_checked="1">
                <a
                  href="https://cl.ctml.in/aRHVlSLzsFfV"
                  class="url-link item-content-box item-block"
                  id="link-eh9ewg6aa2"
                  data-at="image-link"
                  data-link-eh9ewg6aa2=""
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    class="item-content-box item-block image"
                    data-at="image"
                    alt=""
                    src={Images.gullak2}
                    data-src={Images.gullak2}
                    data-retina-src={Images.gullak2}
                    srcset={Images.gullak2}
                  />
                </a>
              </div>
            </div>

            <div
              class="widget item-absolute"
              id="element-468"
              bis_skin_checked="1">
              <div
                class="contents shape circle figure"
                data-at="shape"
                bis_skin_checked="1"></div>
            </div>

            <div
              class="widget item-absolute headline"
              id="element-469"
              data-at="headline"
              bis_skin_checked="1">
              <div class="contents" bis_skin_checked="1">
                <h1>
                  <span class="x_dc68832a">
                    <span class="x_be5cc131">
                      <strong> 3</strong>
                    </span>
                  </span>
                </h1>
              </div>
            </div>

            <div
              class="widget item-absolute paragraph"
              id="element-466"
              data-at="paragraph"
              bis_skin_checked="1">
              <div class="contents" bis_skin_checked="1">
                <p class="x_06cb0376 x_8c071feb">
                  <strong>
                    कस्टमर्स से आर्डर लगवाए और हर आर्डर पे कमाए 10% कमीशन
                  </strong>
                </p>
              </div>
            </div>

            <div
              class="widget item-absolute"
              id="element-473"
              bis_skin_checked="1">
              <div
                class="contents shape circle figure"
                data-at="shape"
                bis_skin_checked="1"></div>
            </div>

            <div
              class="widget item-absolute headline"
              id="element-474"
              data-at="headline"
              bis_skin_checked="1">
              <div class="contents" bis_skin_checked="1">
                <h1>
                  <span class="x_dc68832a">
                    <span class="x_be5cc131">
                      <strong>4</strong>
                    </span>
                  </span>
                </h1>
              </div>
            </div>

            <div
              class="widget item-absolute paragraph"
              id="element-471"
              data-at="paragraph"
              bis_skin_checked="1">
              <div class="contents" bis_skin_checked="1">
                <p class="x_06cb0376 x_8c071feb">
                  <strong>
                    हर आर्डर का कमीशन पाएं सीधे अपने बैंक अकाउंट में&nbsp;
                  </strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        class="section section-relative hidden-mobile"
        id="page-block-05m97vylt1m"
        data-at="section">
        <div
          class="section-holder-border item-block item-absolute"
          data-at="section-border"
          bis_skin_checked="1"></div>
        <div
          class="section-holder-overlay item-block item-absolute"
          data-at="section-overlay"
          bis_skin_checked="1"></div>
        <div class="section-block" bis_skin_checked="1">
          <div
            class="section-inner section-fit section-relative"
            bis_skin_checked="1">
            <div
              class="widget item-absolute headline"
              id="element-779"
              data-at="headline"
              bis_skin_checked="1">
              <div class="contents" bis_skin_checked="1">
                <h1>
                  <span class="x_b614627d x_47b745f5">
                    <strong>
                      कस्टमर्स बनाये और
                      <span class="x_1dad93f4"> कमाई बढ़ाये&nbsp;&nbsp;</span>
                    </strong>
                  </span>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        class="section section-relative"
        id="page-block-ppa8syz3e5"
        data-at="section">
        <div
          class="section-holder-border item-block item-absolute"
          data-at="section-border"
          bis_skin_checked="1"></div>
        <div
          class="section-holder-overlay item-block item-absolute"
          data-at="section-overlay"
          bis_skin_checked="1"></div>
        <div class="section-block" bis_skin_checked="1">
          <div
            class="section-inner section-fit section-relative desktop-only"
            bis_skin_checked="1">
            <div
              class="widget item-absolute"
              id="element-755"
              bis_skin_checked="1">
              <div
                class="contents cropped item-block"
                data-at="image-cropp"
                bis_skin_checked="1">
                <img
                  class="item-content-box item-block image desktop-only"
                  data-at="image"
                  alt=""
                  src={Images.kamai}
                  data-src={Images.kamai}
                  data-retina-src={Images.kamai}
                  srcset={Images.kamai}
                />
              </div>
            </div>
          </div>
          <div
            class="section-inner section-fit section-relative mobile-only"
            bis_skin_checked="1">
            <div
              class="widget item-absolute"
              id="element-755"
              bis_skin_checked="1">
              <div
                class="contents cropped item-block"
                data-at="image-cropp"
                bis_skin_checked="1">
                <img
                  class="item-content-box item-block image mobile-only"
                  data-at="image"
                  alt=""
                  src={Images.customerKamai}
                  data-src={Images.customerKamai}
                  data-retina-src={Images.customerKamai}
                  srcset={Images.customerKamai}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        class="section section-relative"
        id="page-block-euq3gv5pn19"
        data-at="section">
        <div
          class="section-holder-border item-block item-absolute"
          data-at="section-border"
          bis_skin_checked="1"></div>
        <div
          class="section-holder-overlay item-block item-absolute"
          data-at="section-overlay"
          bis_skin_checked="1"></div>
        <div class="section-block" bis_skin_checked="1">
          <div
            class="section-inner section-fit section-relative"
            bis_skin_checked="1">
            <div
              class="widget item-absolute headline"
              id="element-228"
              data-at="headline"
              bis_skin_checked="1">
              <div class="contents" bis_skin_checked="1">
                <h1>
                  <span class="x_11909a12 x_64e665fe">
                    <strong>
                      <span class="x_be5cc131">हमारे साथ जुड़े है</span>&nbsp;
                      <span class="x_1dad93f4">1 करोड़</span>&nbsp;
                      <span class="x_be5cc131">
                        से भी ज्यादा कस्टमर्स&nbsp;
                      </span>
                    </strong>
                  </span>
                </h1>
              </div>
            </div>

            <div
              class="widget item-absolute"
              id="element-226"
              bis_skin_checked="1">
              <div
                class="contents shape box figure"
                data-at="shape"
                bis_skin_checked="1"></div>
            </div>

            <div
              class="widget item-absolute"
              id="element-238"
              bis_skin_checked="1">
              <div
                class="contents shape circle figure"
                data-at="shape"
                data-src={Images.testimonial1}
                bis_skin_checked="1"
                style={{
                  backgroundImage: `url(${Images.testimonial1})`,
                }}></div>
            </div>

            <div
              class="widget item-absolute paragraph"
              id="element-233"
              data-at="paragraph"
              bis_skin_checked="1">
              <div class="contents" bis_skin_checked="1">
                <p class="x_e6507cf2">
                  <em>
                    <span class="x_be5cc131">
                      "मैं ऑनलाइन शॉपिंग से डरती थी
                    </span>
                  </em>
                </p>
                <p class="x_e6507cf2">
                  <em>
                    <span class="x_be5cc131">
                      अब मुझे विश्वास है अपने पार्टनर पे
                    </span>
                  </em>
                  <em class="x_686005f3">
                    <span class="x_be5cc131">"</span>
                  </em>
                </p>
              </div>
            </div>

            <div
              class="widget item-absolute paragraph"
              id="element-234"
              data-at="paragraph"
              bis_skin_checked="1">
              <div class="contents" bis_skin_checked="1">
                <p class="x_dc68832a">
                  <strong>
                    <span class="x_1dad93f4">- स्वाति गुप्ता&nbsp;&nbsp;</span>
                  </strong>
                </p>
                <p class="x_dc68832a">
                  <span class="x_1dad93f4">मथुरा</span>
                </p>
              </div>
            </div>

            <div
              class="widget item-absolute"
              id="element-245"
              bis_skin_checked="1">
              <div
                class="contents shape box figure"
                data-at="shape"
                bis_skin_checked="1"></div>
            </div>

            <div
              class="widget item-absolute"
              id="element-248"
              bis_skin_checked="1">
              <div
                class="contents shape circle figure"
                data-at="shape"
                data-src={Images.testimonial2}
                bis_skin_checked="1"
                style={{
                  backgroundImage: `url(${Images.testimonial2})`,
                }}></div>
            </div>

            <div
              class="widget item-absolute paragraph"
              id="element-246"
              data-at="paragraph"
              bis_skin_checked="1">
              <div class="contents" bis_skin_checked="1">
                <p class="x_e6507cf2">
                  <em>
                    <span class="x_be5cc131">
                      "बढ़ती महंगाई में इनकी शिक्षा की टेंशन रहती थी&nbsp;
                    </span>
                  </em>
                  <em class="x_686005f3">
                    <span class="x_be5cc131">
                      अब हर महीने 800-900 रुपये की बचत होती है
                    </span>
                  </em>
                  <em class="x_686005f3">
                    <span class="x_be5cc131">"</span>
                  </em>
                </p>
              </div>
            </div>

            <div
              class="widget item-absolute paragraph"
              id="element-247"
              data-at="paragraph"
              bis_skin_checked="1">
              <div class="contents" bis_skin_checked="1">
                <p class="x_dc68832a">
                  <strong>
                    <span class="x_1dad93f4">- सुरेश सिंह</span>
                  </strong>
                </p>
                <p class="x_dc68832a">
                  <span class="x_1dad93f4">वाराणसी</span>
                </p>
              </div>
            </div>

            <div
              class="widget item-absolute"
              id="element-252"
              bis_skin_checked="1">
              <div
                class="contents shape box figure"
                data-at="shape"
                bis_skin_checked="1"></div>
            </div>

            <div
              class="widget item-absolute"
              id="element-258"
              bis_skin_checked="1">
              <div
                class="contents shape circle figure"
                data-at="shape"
                data-src={Images.testimonial3}
                bis_skin_checked="1"
                style={{
                  backgroundImage: `url(${Images.testimonial3})`,
                }}></div>
            </div>

            <div
              class="widget item-absolute paragraph"
              id="element-254"
              data-at="paragraph"
              bis_skin_checked="1">
              <div class="contents" bis_skin_checked="1">
                <p class="x_e6507cf2">
                  <em>
                    <span class="x_7b2817bf">
                      "हर दूसरे दिन बाजार जाना, रिकशॉ का खर्चा C
                    </span>
                  </em>
                  <em class="x_686005f3">
                    <span class="x_7b2817bf">ityMall बना है मेरा सहारा"</span>
                  </em>
                </p>
              </div>
            </div>

            <div
              class="widget item-absolute paragraph"
              id="element-256"
              data-at="paragraph"
              bis_skin_checked="1">
              <div class="contents" bis_skin_checked="1">
                <p class="x_dc68832a">
                  <strong>
                    <span class="x_1dad93f4">- सुपर्णा शुक्ला&nbsp;</span>
                  </strong>
                </p>
                <p class="x_dc68832a">
                  <span class="x_1dad93f4">लखनऊ</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        class="section section-relative"
        id="page-block-edqepzfrovn"
        data-at="section">
        <div
          class="section-holder-border item-block item-absolute"
          data-at="section-border"
          bis_skin_checked="1"></div>
        <div
          class="section-holder-overlay item-block item-absolute"
          data-at="section-overlay"
          bis_skin_checked="1"></div>
        <div class="section-block" bis_skin_checked="1">
          <div
            class="section-inner section-fit section-relative"
            bis_skin_checked="1">
            <div
              class="widget item-absolute headline"
              id="element-210"
              data-at="headline"
              bis_skin_checked="1">
              <div class="contents" bis_skin_checked="1">
                <h1>
                  <span class="x_11909a12 x_47b745f5">
                    <strong>
                      <span class="x_be5cc131">क्या कहना है हमारे </span>
                      <span class="x_1dad93f4">बिज़नेस पार्टनर्स </span>
                      <span class="x_be5cc131">का</span>
                    </strong>
                  </span>
                </h1>
              </div>
            </div>

            <div
              class="widget item-absolute"
              id="element-214"
              bis_skin_checked="1">
              <div class="contents item-block" bis_skin_checked="1">
                <iframe
                  class="item-block video"
                  type="text/html"
                  data-src="https://www.youtube.com/embed/rfAzHzArQ5U?showinfo=0&amp;wmode=transparent&amp;rel=0&amp;autoplay=0&amp;mute=0&amp;controls=1"
                  allow="autoplay"
                  allowfullscreen=""
                  data-at="video-youtube"
                  title="2 घंटे रोज = 10,000₹, Part Time Work from Home, Citymall, For Housewife, business and Job person"
                  frameborder="0"
                  src="https://www.youtube.com/embed/rfAzHzArQ5U?showinfo=0&amp;wmode=transparent&amp;rel=0&amp;autoplay=0&amp;mute=0&amp;controls=1"></iframe>
              </div>
            </div>

            <div
              class="widget item-absolute hidden-mobile"
              id="element-212"
              bis_skin_checked="1">
              <div
                class="contents cropped item-block"
                data-at="image-cropp"
                bis_skin_checked="1">
                <a
                  href="https://youtu.be/rfAzHzArQ5U"
                  class="url-link item-content-box item-block"
                  id="link-3zrwyasfkcf"
                  data-at="image-link"
                  data-link-3zrwyasfkcf=""
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    class="item-content-box item-block image"
                    data-at="image"
                    alt=""
                    src="./47752815-0-ic-play-circle-fille.svg"
                    data-src="//v.fastcdn.co/u/6430ff65/47752815-0-ic-play-circle-fille.svg"
                    data-retina-src="//v.fastcdn.co/u/6430ff65/47752815-0-ic-play-circle-fille.svg"
                    srcset="
                      //v.fastcdn.co/u/6430ff65/47752815-0-ic-play-circle-fille.svg 2x
                    "
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        class="section section-relative"
        id="page-block-rprp5g5gpym"
        data-at="section">
        <div
          class="section-holder-border item-block item-absolute"
          data-at="section-border"
          bis_skin_checked="1"></div>
        <div
          class="section-holder-overlay item-block item-absolute"
          data-at="section-overlay"
          bis_skin_checked="1"></div>
        <div class="section-block" bis_skin_checked="1">
          <div
            class="section-inner section-fit section-relative"
            bis_skin_checked="1">
            <div
              class="widget item-absolute headline"
              id="element-109"
              data-at="headline"
              bis_skin_checked="1">
              <div class="contents" bis_skin_checked="1">
                <h1>
                  <span class="x_11909a12 x_f82204f0">
                    <strong>
                      <span class="x_be5cc131">हम हैं </span>
                      <span class="x_1dad93f4">CityMall</span>
                    </strong>
                  </span>
                </h1>
              </div>
            </div>

            <div
              class="widget item-absolute headline"
              id="element-261"
              data-at="headline"
              bis_skin_checked="1">
              <div class="contents" bis_skin_checked="1">
                <h1>
                  <span class="x_2ec5d3d8 x_66d76769">
                    <span class="x_be5cc131">
                      हम है भारत की सबसे तेज़ी से बढ़ते हुई{' '}
                    </span>
                    <span class="x_1dad93f4">इकॉमर्स कंपनी</span>
                    <span class="x_be5cc131">&nbsp;</span>
                  </span>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        class="section section-relative"
        id="page-block-xtxeeaj9qhq"
        data-at="section">
        <div
          class="section-holder-border item-block item-absolute"
          data-at="section-border"
          bis_skin_checked="1"></div>
        <div
          class="section-holder-overlay item-block item-absolute"
          data-at="section-overlay"
          bis_skin_checked="1"></div>
        <div class="section-block" bis_skin_checked="1">
          <div
            class="section-inner section-fit section-relative"
            bis_skin_checked="1">
            <div
              class="widget item-absolute"
              id="element-178"
              bis_skin_checked="1">
              <div
                class="contents shape box figure"
                data-at="shape"
                data-src={Images.team3}
                bis_skin_checked="1"
                style={{ backgroundImage: `url(${Images.team3})` }}></div>
            </div>

            <div
              class="widget item-absolute"
              id="element-177"
              bis_skin_checked="1">
              <div
                class="contents shape box figure"
                data-at="shape"
                data-src={Images.team4}
                bis_skin_checked="1"
                style={{ backgroundImage: `url(${Images.team4})` }}></div>
            </div>
            <div
              class="widget item-absolute"
              id="element-1779"
              bis_skin_checked="1">
              <div
                class="contents shape box figure"
                data-at="shape"
                data-src={Images.team1}
                bis_skin_checked="1"
                style={{
                  backgroundImage: `url(${Images.team1})`,
                  backgroundPosition: 'center center',
                  backgroundSize: 'cover',
                }}></div>
            </div>
            <div
              class="widget item-absolute"
              id="element-179"
              bis_skin_checked="1">
              <div
                class="contents shape box figure"
                data-at="shape"
                data-src={Images.team2}
                bis_skin_checked="1"
                style={{ backgroundImage: `url(${Images.team2})` }}></div>
            </div>
          </div>
        </div>
      </section>

      <section
        class="section section-relative"
        id="page-block-2rymn9d97la"
        data-at="section"
        style={{ marginBottom: '-15%' }}>
        <div
          class="section-holder-border item-block item-absolute"
          data-at="section-border"
          bis_skin_checked="1"></div>
        <div
          class="section-holder-overlay item-block item-absolute"
          data-at="section-overlay"
          bis_skin_checked="1"></div>
        <div class="section-block" bis_skin_checked="1">
          <div
            class="section-inner section-fit section-relative"
            bis_skin_checked="1">
            <div
              class="widget item-absolute headline"
              id="element-582"
              data-at="headline"
              bis_skin_checked="1">
              <div class="contents" bis_skin_checked="1">
                <h1>
                  <span class="x_11909a12 x_f82204f0">
                    <strong>
                      <span class="x_1dad93f4">अख़बारों में</span>
                      <span class="x_be5cc131">&nbsp;उपस्थिति</span>
                    </strong>
                  </span>
                </h1>
              </div>
            </div>

            <div
              class="widget item-absolute"
              id="element-597"
              bis_skin_checked="1">
              <div
                class="contents cropped item-block"
                data-at="image-cropp"
                bis_skin_checked="1">
                <img
                  class="desktop-only"
                  data-at="image"
                  alt=""
                  src={Images.news}
                  data-src={Images.news}
                  data-retina-src={Images.news}
                  srcset={Images.news}
                  style={{ height: 650, width: 1250, marginLeft: '-30%' }}
                />
              </div>
            </div>

            <div
              class="widget item-absolute"
              id="element-5971"
              bis_skin_checked="1">
              <div
                class="contents cropped item-block"
                data-at="image-cropp"
                bis_skin_checked="1">
                <img
                  class="item-content-box item-block image mobile-only"
                  data-at="image"
                  alt=""
                  src={Images.news2}
                  data-src={Images.news2}
                  data-retina-src={Images.news2}
                  srcset={Images.news2}
                />
              </div>
            </div>
            <div
              class="widget item-absolute"
              id="element-598"
              bis_skin_checked="1">
              <div
                class="contents cropped item-block"
                data-at="image-cropp"
                bis_skin_checked="1">
                <img
                  class="item-content-box item-block image mobile-only"
                  data-at="image"
                  alt=""
                  src={Images.news1}
                  data-src={Images.news1}
                  data-retina-src={Images.news1}
                  srcset={Images.news1}
                />
              </div>
            </div>

            <div
              class="widget item-absolute"
              id="element-596"
              bis_skin_checked="1">
              <div
                class="contents cropped item-block mobile-only"
                data-at="image-cropp"
                bis_skin_checked="1">
                <img
                  class="item-content-box item-block image"
                  data-at="image"
                  alt=""
                  src={Images.news3}
                  data-src={Images.news3}
                  data-retina-src={Images.news3}
                  srcset={Images.news3}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        class="section section-relative"
        id="page-block-ej3l1zy2k3t"
        data-at="section">
        <div
          class="section-holder-border item-block item-absolute"
          data-at="section-border"
          bis_skin_checked="1"></div>
        <div
          class="section-holder-overlay item-block item-absolute"
          data-at="section-overlay"
          bis_skin_checked="1"></div>
        <div class="section-block" bis_skin_checked="1">
          <div
            class="section-inner section-fit section-relative"
            bis_skin_checked="1">
            <div
              class="widget item-absolute headline"
              id="element-262"
              data-at="headline"
              bis_skin_checked="1">
              <div class="contents" bis_skin_checked="1">
                <h1>
                  <span class="x_11909a12 x_f82204f0">
                    <strong>
                      <span class="x_1dad93f4">मीडिया</span>
                      <span class="x_be5cc131"> उपस्थिति</span>
                    </strong>
                  </span>
                </h1>
              </div>
            </div>

            <div
              class="widget item-absolute"
              id="element-263"
              bis_skin_checked="1">
              <div
                class="contents shape box figure"
                data-at="shape"
                bis_skin_checked="1"></div>
            </div>

            <div
              class="widget item-absolute"
              id="element-266"
              bis_skin_checked="1">
              <div
                class="contents shape box figure"
                data-at="shape"
                data-src={Images.forbesIndia}
                bis_skin_checked="1"
                style={{ backgroundImage: `url(${Images.forbesIndia})` }}></div>
            </div>

            <div
              class="widget item-absolute paragraph"
              id="element-265"
              data-at="paragraph"
              bis_skin_checked="1">
              <div class="contents" bis_skin_checked="1">
                <p class="x_bcbce646">
                  <a
                    href="https://www.forbesindia.com/article/take-one-big-story-of-the-day/citymall-wants-to-change-how-smalltown-india-buys-groceries/67201/1"
                    id="link-ly20oipgam"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Forbes"
                    data-link-ly20oipgam=""
                    class="url-link">
                    <span class="x_be5cc131">
                      सिटीमॉल छोटे शहरों में किराने का सामान खरीदने के तरीके को
                      बदलना चाहता है
                    </span>
                  </a>
                </p>
              </div>
            </div>

            <div
              class="widget item-absolute"
              id="element-279"
              bis_skin_checked="1">
              <div
                class="contents cropped item-block"
                data-at="image-cropp"
                bis_skin_checked="1">
                <img
                  class="item-content-box item-block image"
                  data-at="image"
                  alt=""
                  src={Images.forbes}
                  data-src={Images.forbes}
                  data-retina-src={Images.forbes}
                  srcset={Images.forbes}
                />
              </div>
            </div>

            <div
              class="widget item-absolute"
              id="element-287"
              bis_skin_checked="1">
              <div
                class="contents shape box figure"
                data-at="shape"
                data-src={Images.cnbc}
                bis_skin_checked="1"
                style={{ backgroundImage: `url(${Images.cnbc})` }}></div>
            </div>

            <div
              class="widget item-absolute paragraph"
              id="element-285"
              data-at="paragraph"
              bis_skin_checked="1">
              <div class="contents" bis_skin_checked="1">
                <p class="x_bcbce646">
                  <a
                    href="https://www.cnbctv18.com/business/companies/citymall-raises-225-million-in-series-b-funding-led-by-general-catalyst-jungle-ventures-9738681.htm"
                    id="link-9danvw62st"
                    target="_blank"
                    rel="noopener noreferrer"
                    data-link-9danvw62st=""
                    class="url-link">
                    <span class="x_be5cc131">
                      सिटीमॉल ने जनरल कैटालिस्ट के नेतृत्व में सीरीज बी फंडिंग
                      में 22.5 मिलियन डॉलर जुटाए
                    </span>
                  </a>
                </p>
              </div>
            </div>

            <div
              class="widget item-absolute"
              id="element-283"
              bis_skin_checked="1">
              <div
                class="contents shape box figure"
                data-at="shape"
                bis_skin_checked="1"></div>
            </div>

            <div
              class="widget item-absolute"
              id="element-289"
              bis_skin_checked="1">
              <div
                class="contents cropped item-block"
                data-at="image-cropp"
                bis_skin_checked="1"></div>
            </div>

            <div
              class="widget item-absolute"
              id="element-319"
              bis_skin_checked="1">
              <div
                class="contents shape box figure"
                data-at="shape"
                data-src={Images.techinasi}
                bis_skin_checked="1"
                style={{ backgroundImage: `url(${Images.techinasi})` }}></div>
            </div>

            <div
              class="widget item-absolute paragraph"
              id="element-317"
              data-at="paragraph"
              bis_skin_checked="1">
              <div class="contents" bis_skin_checked="1">
                <p class="x_bcbce646">
                  <a
                    href="https://www.techinasia.com/indian-social-commerce-startup-nets-75m-series-money"
                    id="link-fw301ialcsj"
                    target="_blank"
                    rel="noopener noreferrer"
                    data-link-fw301ialcsj=""
                    class="url-link">
                    <span class="x_be5cc131">
                      भारतीय सोशल ई-कॉमर्स स्टार्टअप ने सीरीज सी मनी में $75
                      मिलियन की कमाई की
                    </span>
                  </a>
                </p>
              </div>
            </div>

            <div
              class="widget item-absolute"
              id="element-314"
              bis_skin_checked="1">
              <div
                class="contents shape box figure"
                data-at="shape"
                bis_skin_checked="1"></div>
            </div>

            <div
              class="widget item-absolute"
              id="element-321"
              bis_skin_checked="1">
              <div
                class="contents cropped item-block"
                data-at="image-cropp"
                bis_skin_checked="1"></div>
            </div>
          </div>
        </div>
      </section>

      <section
        class="section section-relative"
        id="page-block-jkogznfscv"
        data-at="section">
        <div
          class="section-holder-border item-block item-absolute"
          data-at="section-border"
          bis_skin_checked="1"></div>
        <div
          class="section-holder-overlay item-block item-absolute"
          data-at="section-overlay"
          bis_skin_checked="1"></div>
        <div class="section-block" bis_skin_checked="1" style={{
                height:'250px'
              }}  >
          <div
            class="section-inner section-fit section-relative"
            bis_skin_checked="1">
            {/* <div
              class="widget item-absolute headline"
              id="element-781"
              data-at="headline"
              bis_skin_checked="1">
              <div class="contents" bis_skin_checked="1">
                <h1>
                  <span class="x_e6507cf2 x_2c80ab57">
                    <span class="x_2b6404b1">
                      <Link
                        id="link-899rqsfnj1k"
                        to="/terms-and-conditions"
                        target="_blank"
                        class="url-link">
                        Terms &amp; Conditions
                      </Link>
                    </span>
                  </span>
                </h1>
              </div>
            </div>

            <div
              class="widget item-absolute headline"
              id="element-782"
              data-at="headline"
              bis_skin_checked="1">
              <div class="contents" bis_skin_checked="1">
                <h1>
                  <span class="x_e6507cf2 x_2c80ab57">
                    <span class="x_2b6404b1">
                      <Link
                        id="link-gtvxqoskj2b"
                        to="/privacy-policy"
                        target="_blank"
                        class="url-link">
                        Privacy Policy
                      </Link>
                    </span>
                  </span>
                </h1>
              </div>
            </div>

            <div
              class="widget item-absolute headline"
              id="element-783"
              data-at="headline"
              bis_skin_checked="1">
              <div class="contents" bis_skin_checked="1">
                <span class="x_e6507cf2 x_2c80ab57">
                  <span class="x_2b6404b1">
                    <Link
                      id="link-0i28p2l0i2dg"
                      to="/return-policy"
                      target="_blank"
                      class="url-link"
                      style={{ fontWeight: 520 }}>
                      Return Policy
                    </Link>
                  </span>
                </span>
              </div>
            </div> */}

            <div
              class="widget item-absolute"
              id="element-784"
              bis_skin_checked="1">
              <div
                class="contents cropped item-block"
                data-at="image-cropp"
                bis_skin_checked="1">
                <img
                  class="item-content-box item-block image"
                  data-at="image"
                  alt=""
                  src={Images.message}
                  data-src={Images.message}
                  data-retina-src={Images.message}
                  srcset={Images.message}
                />
              </div>
            </div>

            <div
              class="widget item-absolute headline"
              id="element-785"
              data-at="headline"
              bis_skin_checked="1">
              <div class="contents" bis_skin_checked="1">
                <h1>
                  <span class="x_3579aa00 x_fae6260b">
                    <span class="x_2b6404b1">support@citymall.live</span>
                  </span>
                </h1>
              </div>
            </div>

            <div
              class="widget item-absolute paragraph"
              id="element-75"
              data-at="paragraph"
              bis_skin_checked="1"
            >
              <div class="contents" bis_skin_checked="1" >
                <p>
                  <span style={{color:'white'}}>
                    CityMall (Cmunity Innovations Pvt. Ltd.) Plot No 65, Sector 44, Gurugram, Haryana 122003
                  </span>
                </p>
                <p>
                  <span style={{color:'white'}}>
                  RASHANBOX RETAIL PRIVATE LIMITED
                  </span>
                </p>
                <p class="x_938e8cfc x_c5f0bf77">
                  <span style={{marginTop:'20px'}} class="x_76ffb0ab">
                    Copyright © 2020. All rights reserved.
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        class="section section-relative sticky-footer"
        style={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          width: '100%',
          zIndex: '1000000',
        }}
        id="page-block-wlj9406vt9"
        data-at="section">
        {document.addEventListener('DOMContentLoaded', stickyFooter)}
        {window.addEventListener('resize', stickyFooter)}
        <div
          class="section-holder-border item-block item-absolute"
          data-at="section-border"
          bis_skin_checked="1"></div>
        <div
          class="section-holder-overlay item-block item-absolute"
          data-at="section-overlay"
          bis_skin_checked="1"></div>
        <div class="section-block" bis_skin_checked="1">
          <div
            class="section-inner section-fit section-relative"
            bis_skin_checked="1">
            <div
              class="widget item-absolute"
              id="element-681"
              bis_skin_checked="1">
              <div
                class="contents cropped item-block"
                data-at="image-cropp"
                bis_skin_checked="1">
                <a
                  href="https://cl.ctml.in/aRHVlSLzsFfV"
                  class="url-link item-content-box item-block"
                  id="link-jk0qisj9vy"
                  data-at="image-link"
                  data-link-jk0qisj9vy=""
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    class="item-content-box item-block image"
                    data-at="image"
                    alt=""
                    src={Images.downloadRedirect}
                    data-src={Images.downloadRedirect}
                    data-retina-src={Images.downloadRedirect}
                    srcset={Images.downloadRedirect}
                  />
                </a>
              </div>
            </div>

            <div
              class="widget item-absolute headline"
              id="element-432"
              data-at="headline"
              bis_skin_checked="1">
              <div class="contents" bis_skin_checked="1">
                <h1>
                  <span class="x_56c6f2d0 x_0a34168d">
                    <span class="x_f2074b6c">
                      <strong>
                        <a
                          href="https://cl.ctml.in/aRHVlSLzsFfV"
                          id="link-93blvea4tj7"
                          target="_blank"
                          rel="noopener noreferrer"
                          data-link-93blvea4tj7=""
                          class="url-link">
                          आप भी CityMall पार्टनर बने और कमाना शुरू करे
                        </a>
                      </strong>
                    </span>
                  </span>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default App;
