import React from 'react';
import './styles/return-policy.css';

const ReturnPolicy = () => {
  return (
    <div data-server-rendered="true" id="__nuxt" bis_skin_checked="1">
      <div id="__layout" bis_skin_checked="1">
        <div
          class="container"
          data-v-6f73910a=""
          bis_skin_checked="1"
          style={{ marginRight: '', width: '' }}>
          <h1 data-v-6f73910a="" id="title">
            Return and refund policy
          </h1>
          <br></br>
          <div data-v-6f73910a="" id="para1">
            1. What are the return guidelines?<br></br> <br></br>Given below are
            the return guidelines:<br></br> <br></br>
            <ol style={{ marginLeft: '20px' }}>
              <li>
                1. An item can be returned only if the return request has been
                raised within the return window (refer to section 3 for more
                information on return window).
              </li>
              <li>
                2. An item will not be eligible for return after expiry of the
                return window.
              </li>
              <li>
                3. Return and refund will be processed only if:{' '}
                <p style={{ marginLeft: '20px' }}>
                  a. it is determined that the product was not damaged while in
                  your possession;{' '}
                </p>
                <p style={{ marginLeft: '20px' }}>
                  b. the product is not different from what was shipped to you;
                </p>
                <p style={{ marginLeft: '20px' }}>
                  c. the product is returned in original condition (with
                  original packaging, MRP tag intact, warranty card and all the
                  accessories therein).
                </p>
              </li>
              <li>
                4. Items masked as “non-returnable” over the product detail page
                on the CityMall app cannot be returned.
              </li>
              <li>
                5. An item will be returned only after verification at the time
                of pick-up. If an item is not eligible for return, it will not
                be picked up.
              </li>
              <li>
                6. There will be two pick-up attempts by CityMall to pick an
                item. In case both the pick-up attempts are unsuccessful, the
                return shall stand canceled.
              </li>
              <li>
                7. After an item is received and verified by CityMall, the
                refund would be initiated within 24 hours of it.
              </li>
              <li>
                8. Refund will not be initiated if an item is handed over to the
                delivery person without scheduling a return.<br></br> Always
                place a return request first and hand over the item to the
                delivery person only after a return request is approved.
              </li>
              <li>
                9. Kindly do not accept an order at the time of delivery, if
                received in damaged or tampered condition.{' '}
              </li>
            </ol>
          </div>
          <br></br>
          <div data-v-6f73910a="" id="para2">
            2. What can I return?<br></br>
            <br></br>You may request returns for most items you buy from the
            CityMall app. <br></br>Please refer to the “Return/Refund” section
            of the product detail page to check the ‘return window’ of an item.
            <br></br>
            Items are returnable if you've received them in a condition that is:{' '}
            <br></br>
            <ul style={{ marginLeft: '20px' }}>
              <li>• Product damaged</li>
              <li>• Expired product</li>
              <li>• Partial item(s) or accessories not found</li>
              <li>• Product MRP related issue</li>
              <li>• MRP से सम्बंधित समस्या हो</li>
              <li>• Issue with item or item quality</li>
              <li>• Wrong item delivered</li>
              <li>• Product size/color issue</li>
              <li>• Item does not match the image of info or the app.</li>
            </ul>
            <br></br>
            Once the product has been received after verification, we will
            process the refund and update you once done.{' '}
          </div>
          <br></br>
          <div data-v-6f73910a="" id="para3">
            3. What is the return period in which an item can be returned?
            <br></br> To return an item, kindly raise a return request within
            the defined ‘return window’ after the delivery<br></br> (refer to
            section 4 for more information on how to raise a return request).
            <br></br> <br></br>Kindly note that you can request return for most
            items you buy from the CityMall app,<br></br>
            however, items explicitly identified as "non-returnable" on the
            product detail page cannot be returned.
          </div>
          <br></br>
          <div class="table-wrapper" data-v-6f73910a="" bis_skin_checked="1">
            <table data-v-6f73910a="" id="table1">
              <tr data-v-6f73910a="true">
                <th data-v-6f73910a="true">Category</th>
                <th data-v-6f73910a="true">Return window</th>
              </tr>
              <tr data-v-6f73910a="true">
                <td data-v-6f73910a="true">
                  {' '}
                  Atta, Sugar, Salt, Oil &amp; Ghee Dal, Rice, Spices &amp; Dry
                  Fruit Snacks Beverages Packaged Food
                </td>
                <td data-v-6f73910a="true">3 days</td>
              </tr>
              <tr data-v-6f73910a="true">
                <td data-v-6f73910a="true">
                  {' '}
                  Home Care Personal Care Household Items Baby Care Men Fashion
                  Women Fashion Home &amp; Kitchen Beauty &amp; Health
                  Electronics Bags, Luggage &amp; Travel Kids &amp; Toys
                  Marketing Collaterals{' '}
                </td>
                <td data-v-6f73910a="true">7 days</td>
              </tr>
            </table>
          </div>
          <div data-v-6f73910a="" id="para4">
            4. How to return an item? <br></br>
            To return an item, kindly raise a return request from your app
            within the defined ‘return window’.<br></br>
            <br></br>Follow the instructions given below to raise a return
            request-
            <ol style={{ marginLeft: '20px' }}>
              <li>
                1. Go to the “Order” section under the profile page of the app
              </li>
              <li>2. Select the order in which you are facing an issue</li>
              <li>
                3. Press the ‘return’ button in front of the item you want to
                return
              </li>
              <li>
                4. Select the quantity. Press + (plus) to increase the quantity
                and -(minus) to reduce the quantity
              </li>
              <li>
                5. Select the reason to return the item and elaborate the issue
                in the description box given below
              </li>
              <li>
                6. Upload a verifiable image of the item and press “Submit”
              </li>
              <li>
                7. Choose a desirable date for the pick-up and ‘Submit’ the
                request.
              </li>
            </ol>
            <br></br>A pick-up would be scheduled after verification of the
            image uploaded by you.<br></br> The item would be picked up from the
            address on the scheduled date after an OTP verification.
          </div>
          <br></br>
          <div data-v-6f73910a="" id="para5">
            5. How to check return status?<br></br> To check the return status
            and related information, follow the steps given below-
            <ol style={{ marginLeft: '20px' }}>
              <li>
                1. Go to the “Order” section under the profile page of the app
              </li>
              <li>2. Go to the “Returns” page and select the order.</li>
            </ol>
            <br></br>
            You will be able to see the details related to the return.
          </div>
          <br></br>
          <div data-v-6f73910a="" id="para6">
            6. Can I replace/exchange an item? <br></br>If you receive an item
            in any of the aforementioned conditions in the Section 2, <br></br>
            you may return the item by raising a return request from your app
            after which refund would be processed. <br></br>Kindly refer to
            Section 4 to check how to raise a return request. <br></br>
            <br></br>Kindly note that we do not provide the service to replace
            or exchange an item.
          </div>
          <br></br>
          <div data-v-6f73910a="" id="para7">
            7. What to do if an item is missing or not found in my order?{' '}
            <br></br>
            If there is an item(s) less in your order or an item(s) has not been
            delivered by CityMall to you,<br></br> follow the steps given below
            to raise a complaint-
            <ol style={{ marginLeft: '20px' }}>
              <li>
                1. Go to the “Order” section under the profile page of the app
              </li>
              <li>2. Select the order in which you are facing an issue</li>
              <li>
                3. Press the ‘return’ button in front of the item you want to
                return
              </li>
              <li>
                4. Select the quantity. Press + (plus) to increase the quantity
                and -(minus) to reduce the quantity
              </li>
              <li>
                5. Select the reason as per the case- “Item not found in order”
                or “Free item not found”, <br></br>to return the item and
                elaborate the issue in the description box given below, Press
                “Submit”.
              </li>
            </ol>
            <br></br>
            Our team will investigate the issue and provide a resolution at the
            earliest.{' '}
          </div>
          <br></br>
          <div data-v-6f73910a="" id="para8">
            8. When will I receive my refund?<br></br>
            We will initiate a refund from our end within 24 hours after
            receiving the product.<br></br>Once the refund has been processed,
            you will receive the refund within the time-frame given below-
          </div>
          <div class="table-wrapper" data-v-6f73910a="" bis_skin_checked="1">
            <table data-v-6f73910a="" id="table2">
              <tr data-v-6f73910a="true">
                <th data-v-6f73910a="true">Payment method</th>
                <th data-v-6f73910a="true">Refund method</th>
                <th data-v-6f73910a="true">Refund time-frame</th>
              </tr>
              <tr data-v-6f73910a="true">
                <td data-v-6f73910a="true">Cash</td>
                <td data-v-6f73910a="true">Leader gullak</td>
                <td data-v-6f73910a="true">Within 24 hours</td>
              </tr>
              <tr data-v-6f73910a="true">
                <td data-v-6f73910a="true">
                  Online (UPI/wallet/card/netbanking)
                </td>
                <td data-v-6f73910a="true">Online</td>
                <td data-v-6f73910a="true">Within 5-7 working days</td>
              </tr>
            </table>
          </div>
          <div data-v-6f73910a="" id="para9">
            If you are not connected to any leader and have made a payment via
            cash,<br></br>your refund would be provided through an online link
            which would be available on your CityMall app.<br></br>The link
            would also be sent by Razorpay via SMS to your registered mobile
            number.<br></br>Fill the account details on the link provided to
            claim the refund.<br></br>
            <br></br>
            Follow the instructions given below to get the refund via CityMall
            app-
            <ol style={{ marginLeft: '20px' }}>
              <li>
                1. Go to the “Order” section under the profile page of the app
              </li>
              <li>
                2. There will be 2 options on the top of the screen- Order and
                Return. Press “Return”.
              </li>
              <li>
                3. The order for which refund has been given would now be
                visible on the screen. Press the red button below- “अकाउंट डिटेल
                डालें”.
              </li>
              <li>
                4. You would be able to see the refund amount on the screen.
                Press the “VERIFY YOURSELF” option below. <br></br>You will
                receive an OTP on your registered mobile number. Fill the OTP
                for verification.
              </li>
              <li>
                5. Provide the account details in the option “GIVE ACCOUNT
                DETAILS” and click on “CONFIRM AND SUBMIT”.
              </li>
            </ol>
            <br></br>
            The refund would be credited into your account within 24 hours post
            submitting the details on the link.
          </div>
          <br></br>
          <div data-v-6f73910a="" id="para10">
            9. Why was my return or refund rejected? <br></br>
            Your return/refund request might get rejected because of the
            following reasons-
            <ol style={{ marginLeft: '20px' }}>
              <li>1. If the return window for the item is over</li>
              <li>
                2. If the item is non-returnable (refer to Section 3 for more
                information)
              </li>
              <li>
                3. If our team confirms post investigation that the item was
                correctly packed and delivered by CityMall
              </li>
              <li>
                4. If you have selected incorrect reason for return of the
                product
              </li>
              <li>
                5. If the image provided by you is incorrect or unverifiable
              </li>
              <li>6. If you have higher than average refund history</li>
              <li>
                7. If the delivery OTP for the order has been verified by you or
                your leader and the order is missing as per the complaint.
              </li>
            </ol>
            <br></br>
            Kindly share the delivery OTP only after you have received your
            order.
          </div>
          <br></br>
          <div data-v-6f73910a="" id="para11">
            10. How to apply a coupon? <br></br>
            <br></br>Follow the steps given below to apply a coupon-
            <ol style={{ marginLeft: '20px' }}>
              <li>
                1. Choose the products on the CityMall app that you wish to
                order
              </li>
              <li>2. Click on “View Cart”</li>
              <li>
                3. The offers would be visible on the top of the screen under
                “Your Offers”.<br></br> Go to the “View all” option to check all
                coupons, offers and their details.
              </li>
              <li>
                4. Click on the “Apply” button next to the desirable coupon to
                apply it on the order.
              </li>
            </ol>
            <br></br>
            The coupon will now be applied to the order!
          </div>
          <div class="table-wrapper" data-v-6f73910a="" bis_skin_checked="1">
            <table
              cellspacing="0"
              cellpadding="0"
              dir="ltr"
              border="1"
              data-v-6f73910a="">
              <colgroup data-v-6f73910a="">
                <col width="152" data-v-6f73910a=""></col>
                <col width="100" data-v-6f73910a=""></col>
                <col width="100" data-v-6f73910a=""></col>
                <col width="100" data-v-6f73910a=""></col>
                <col width="265" data-v-6f73910a=""></col>
              </colgroup>
              <tbody data-v-6f73910a="" id="table2"></tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReturnPolicy;
